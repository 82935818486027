import mapVerify from '@/utils/formValidate'
import myTransform from '@/libs_sz/utils/transform'
const getFormatter = (data, value) => {
  const getValue =
    myTransform.arrToObject(data, 'value', 'label')[value] || value
  return getValue === 'null' || getValue === 0 ? '' : getValue
}
const getValue = (value) =>
  value ? String(value) : String(value) === '0' ? '0' : ''

// 获取 更多查询字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 外部上下料单号
    {
      name: 'materialsUpDownBillNum',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.updownMaterialOutNo')
    },
    // 工厂编码
    {
      name: 'factoryId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.factoryId'),
      filterable: true,
      data: that.factoryList,
      mchange: that.handleChange
    },
    // 仓库编码
    {
      name: 'warehouseId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.warehouseId'),
      filterable: true,
      disabled: !!formModel.workshopId,
      data: that.queryWarehouseList || [],
      mchange: that.handleChange
    },
    // 车间编码
    {
      name: 'workshopId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.workshopId'),
      filterable: true,
      disabled: !!formModel.warehouseId,
      data: that.queryWorkshopList || [],
      mchange: that.handleChange
    },
    // 区域编码
    {
      name: 'areaId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.areaId'),
      filterable: true,
      disabled:
        !!formModel.warehouseId ||
        !!formModel.warehouseAreaId ||
        !!formModel.factoryPositionId,
      data: that.queryAreaList || [],
      mchange: that.handleChange
    },
    // 产线编码
    {
      name: 'productionLineId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.productionLineId'),
      filterable: true,
      disabled:
        !!formModel.warehouseId ||
        !!formModel.warehouseAreaId ||
        !!formModel.factoryPositionId,
      data: that.queryProductionLineList || [],
      mchange: that.handleChange
    },
    // 库区编码
    {
      name: 'warehouseAreaId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.warehouseAreaId'),
      filterable: true,
      disabled:
        !!formModel.areaId ||
        !!formModel.productionLineId ||
        !!formModel.deviceId ||
        !!formModel.goodsPositionId,
      data: that.queryWarehouseAreaList || [],
      mchange: that.handleChange
    },
    // 库位编码
    {
      name: 'factoryPositionId',
      span: 12,
      component: 'RemoteSelect',
      label: that.$t('lang.gles.base.factoryPositionCode'),
      clearable: true,
      labelText: 'factoryPositionCode',
      disabled:
        !!formModel.areaId ||
        !!formModel.productionLineId ||
        !!formModel.deviceId ||
        !!formModel.goodsPositionId,
      mchange: that.handleChange
    },
    // 设备编码
    {
      name: 'deviceId',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.deviceId'),
      filterable: true,
      disabled:
        !!formModel.warehouseId ||
        !!formModel.warehouseAreaId ||
        !!formModel.factoryPositionId,
      data: that.queryDeviceList || [],
      mchange: that.handleChange
    },
    // 设备货位编码
    {
      name: 'goodsPositionId',
      span: 12,
      component: 'RemoteSelect',
      queryType: 'goodsPositionList',
      label: that.$t('lang.gles.orderManage.deviceGoodsPositionId'),
      filterable: true,
      disabled:
        !!formModel.warehouseId ||
        !!formModel.warehouseAreaId ||
        !!formModel.factoryPositionId,
      // data: that.queryGoodsPositionList || [],
      mchange: that.handleChange
    },
    // 单据类型
    {
      name: 'receiptType',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.orderManage.receiptType'),
      filterable: true,
      data: that.materialsBillReceiptTypeList || []
    },
    // 创建人
    {
      name: 'createUser',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.common.createUser')
    },
    // 状态
    {
      name: 'billStatus',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.status'),
      filterable: true,
      data: that.materialsBillStatusList || []
    },
    // 创建时间
    {
      name: 'createTime',
      span: 12,
      component: 'elDatePicker',
      type: 'datetimerange',
      'range-separator': '-',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      label: that.$t('lang.gles.common.createTime')
    },
    // 编辑时间
    {
      name: 'updateTime',
      span: 12,
      component: 'elDatePicker',
      type: 'datetimerange',
      'range-separator': '-',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      label: that.$t('lang.gles.common.updateTime')
    },
    // 完成时间
    {
      name: 'completedTime',
      span: 12,
      component: 'elDatePicker',
      type: 'datetimerange',
      'range-separator': '-',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      label: that.$t('lang.gles.common.completedTime')
    }
  ]
  return moreQueryData
}

// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 外部上下料单号
    {
      prop: 'materialsUpDownBillNum',
      isShow: true,
      label: that.$t('lang.gles.orderManage.updownMaterialOutNo'),
      minWidth: 168,
      showOverflowTooltip: true,
      slotName: 'clickSlot'
    },
    // 单据类型
    {
      prop: 'receiptType',
      isShow: true,
      label: that.$t('lang.gles.orderManage.receiptType'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(that.materialsBillReceiptTypeList, row[column])
      }
    },
    // 状态
    {
      prop: 'billStatus',
      isShow: true,
      label: that.$t('lang.gles.base.status'),
      width: 80,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(that.materialsBillStatusList, row[column])
      }
    },
    // 异常描述
    {
      prop: 'exceptionDesc',
      isShow: true,
      label: that.$t('lang.gles.orderManage.exceptionDesc'),
      showOverflowTooltip: true,
      formatter(row, column) {
        return that.$t(row[column])
      }
    },
    // 工厂编码
    {
      prop: 'factoryCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.factoryId'),
      width: 100
      // formatter(row, column) {
      //   return getFormatter(that.factoryList, row[column])
      // }
    },
    // 仓库编码
    {
      prop: 'warehouseCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.warehouseId'),
      width: 100
      // formatter(row, column) {
      //   return getFormatter(that.warehouseList, row[column])
      // }
    },
    // 车间编码
    {
      prop: 'workshopCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.workshopId'),
      width: 100
      // formatter(row, column) {
      //   return getFormatter(that.workshopList, row[column])
      // }
    },
    // 区域编码
    {
      prop: 'areaCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.areaId'),
      width: 100
      // formatter(row, column) {
      //   return getFormatter(that.areaList, row[column])
      // }
    },
    // 产线编码
    {
      prop: 'productionLineCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.productionLineId'),
      width: 100
      // formatter(row, column) {
      //   return getFormatter(that.productionLineList, row[column])
      // }
    },
    // 库区编码
    {
      prop: 'warehouseAreaCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.warehouseAreaId'),
      width: 100
      // formatter(row, column) {
      //   return getFormatter(that.warehouseAreaList, row[column])
      // }
    },
    // 库位编码
    {
      prop: 'factoryPositionCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.factoryPositionId'),
      width: 100
      // formatter(row, column) {
      //   return that.factoryPositionList.find(item => item.value === row[column])?.label
      // }
    },
    // 设备编码
    {
      prop: 'deviceCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.deviceId'),
      width: 100
      // formatter(row, column) {
      //   return getFormatter(that.deviceList, row[column])
      // }
    },
    // 设备货位编码
    {
      prop: 'goodsPositionCode',
      isShow: true,
      label: that.$t('lang.gles.orderManage.deviceGoodsPositionId'),
      width: 110
      // formatter(row, column) {
      //   return that.goodsPositionList.find(item => item.value === row[column])?.label
      // }
    },
    // 创建人
    {
      prop: 'createUser',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.common.createUser')
    },
    // 创建时间
    {
      prop: 'createTime',
      isShow: true,
      label: that.$t('lang.gles.common.createTime'),
      formatter: { type: 'time' },
      minWidth: 160,
      showOverflowTooltip: true
    },
    // 修改时间
    {
      prop: 'updateTime',
      isShow: true,
      label: that.$t('lang.gles.common.updateTime'),
      formatter: { type: 'time' },
      minWidth: 160,
      showOverflowTooltip: true
    },
    // 完成时间
    {
      prop: 'completedTime',
      isShow: true,
      label: that.$t('lang.gles.common.completedTime'),
      formatter: { type: 'time' },
      minWidth: 160,
      showOverflowTooltip: true
    }
  ]
  return searchTableItem
}

// 基础信息
export const getEditBaseFormData = (that, row, options) => [
  // 外部上下料单号
  {
    name: 'externalMaterialsUpDownBillNum',
    value: row.externalMaterialsUpDownBillNum || '',
    span: 8,
    component: 'elInput',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    rules: mapVerify(['required']),
    label: that.$t('lang.gles.orderManage.updownMaterialOutNo')
  },
  // 工厂编码
  {
    name: 'factoryId',
    span: 8,
    value: row.factoryId || '',
    component: 'elSelect',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    label: that.$t('lang.gles.orderManage.factoryId'),
    filterable: true,
    data: options.factoryList
  },
  // 车间编码
  {
    name: 'workshopId',
    value: row.workshopId || '',
    span: 8,
    component: 'elSelect',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    label: that.$t('lang.gles.orderManage.workshopId'),
    filterable: true,
    data: options.workshopList || []
  },
  // 仓库编码
  {
    name: 'warehouseId',
    value: row.warehouseId || '',
    span: 8,
    component: 'elSelect',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    label: that.$t('lang.gles.orderManage.warehouseId'),
    filterable: true,
    data: options.warehouseList || []
  },
  // 区域编码
  {
    name: 'areaId',
    value: row.areaId || '',
    span: 8,
    component: 'elSelect',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    label: that.$t('lang.gles.orderManage.areaId'),
    filterable: true,
    data: options.areaList || []
  },
  // 产线编码
  {
    name: 'productionLineId',
    value: row.productionLineId || '',
    span: 8,
    component: 'elSelect',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    label: that.$t('lang.gles.orderManage.productionLineId'),
    filterable: true,
    data: options.productionLineList || []
  },
  // 库区编码
  {
    name: 'warehouseAreaId',
    value: row.warehouseAreaId || '',
    span: 8,
    component: 'elSelect',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    label: that.$t('lang.gles.orderManage.warehouseAreaId'),
    filterable: true,
    data: options.warehouseAreaList || []
  },
  // 库位编码
  {
    name: 'factoryPositionId',
    value: row.factoryPositionId || '',
    span: 8,
    component: 'RemoteSelect',
    label: that.$t('lang.gles.base.factoryPositionCode'),
    labelText: 'factoryPositionCode',
    showLabel: 'factoryPositionCode',
    disabled: true
  },
  // 设备编码
  {
    name: 'deviceId',
    value: row.deviceId || '',
    span: 8,
    component: 'elSelect',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    label: that.$t('lang.gles.orderManage.deviceId'),
    filterable: true,
    data: options.deviceList || []
  },
  // 设备货位编码
  {
    name: 'goodsPositionId',
    value: row.goodsPositionId || '',
    span: 8,
    component: 'RemoteSelect',
    queryType: 'goodsPositionList',
    showLabel: 'goodsPositionCode',
    disabled: true,
    label: that.$t('lang.gles.orderManage.deviceGoodsPositionId')
  },
  // 单据类型
  {
    name: 'receiptType',
    value: Number(getValue(row.receiptType)),
    span: 8,
    component: 'elSelect',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    label: that.$t('lang.gles.orderManage.receiptType'),
    filterable: true,
    rules: mapVerify(['required']),
    data: options.materialsBillReceiptTypeList || []
  },
  // 执行模式
  {
    name: 'executeMode',
    value: row.executeMode ?? 0,
    span: 8,
    component: 'elSelect',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    rules: mapVerify(['required']),
    label: that.$t('lang.gles.workflow.executeMode'),
    filterable: true,
    data: options.executeModeList
  },
  // 备注
  {
    name: 'remark',
    value: row.remark || '',
    span: 8,
    component: 'elInput',
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    label: that.$t('lang.gles.orderManage.remark')
  },
  // 扩展字段
  {
    name: 'externalParams',
    value: row.externalParams || '',
    span: 8,
    adaptionw: true,
    showWordLimit: false,
    disabled: true,
    label: that.$t('lang.gles.common.expangField'),
    slotName: 'externalParamsSlot'
  }
]

// 容器/物料详情列表(有容器)
export const getDetailTableItems = (that, options) => {
  const searchTableItem = [
    // 移动容器类型
    {
      prop: 'containerTypeId',
      isShow: true,
      label: that.$t('lang.gles.common.moveContainerType'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.containerTypeList, row[column])
      }
    },
    // 移动容器编码
    {
      prop: 'containerArchivesId',
      isShow: !that.executeMode,
      label: that.$t('lang.gles.common.moveContainerCode'),
      formatter(row, column) {
        return getFormatter(options.containerArchivesAllList, row[column])
      }
    },
    // 移动容器编码
    {
      prop: 'hostMoveContainerCode',
      isShow: that.executeMode === 1,
      label: that.$t('lang.gles.common.moveContainerCode')
    },
    // 移动容器数量
    {
      prop: 'containerNumber',
      isShow: true,
      label: that.$t('lang.gles.orderManage.moveContainerSum'),
      showOverflowTooltip: true
    },
    // 指定入/出库库区
    {
      prop: 'warehouseAreaId',
      isShow: true,
      showOverflowTooltip: true,
      label:
        that.materialsType === '2'
          ? that.$t('lang.gles.orderManage.assignInWarehouseAreaId')
          : that.$t('lang.gles.orderManage.assignOutWarehouseAreaId'),
      formatter(row, column) {
        return getFormatter(options.warehouseAreaList, row[column])
      }
    },
    // 指定入/出库库位
    {
      prop: 'factoryPositionId',
      isShow: true,
      showOverflowTooltip: true,
      label:
        that.materialsType === '2'
          ? that.$t('lang.gles.orderManage.assignInWarehousePositionId')
          : that.$t('lang.gles.orderManage.assignOutWarehousePositionId'),
      formatter(row, column) {
        return getFormatter(options.factoryPositionList, row[column])
      }
    },
    // 指定入/出库货位
    {
      prop: 'goodsPositionId',
      isShow: true,
      showOverflowTooltip: true,
      label:
        that.materialsType === '2'
          ? that.$t('lang.gles.orderManage.assignInGoodsPositionId')
          : that.$t('lang.gles.orderManage.assignOutGoodsPositionId'),
      formatter(row, column) {
        return getFormatter(options.goodsPositionList, row[column])
      }
    }
  ]
  return searchTableItem.filter(t => t.isShow)
}

// 容器/物料详情子列表(有容器)
export const getDetailChildTableItems = (that, options) => {
  const searchTableItem = [
    // 移动货位编码
    {
      prop: 'goodsPositionId',
      isShow: !that.executeMode,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.moveGoodsPositionId'),
      formatter(row, column) {
        return getFormatter(options.goodsPositionList, row[column])
      }
    },
    {
      prop: 'externalGoodsPositionCode',
      isShow: that.executeMode,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.moveGoodsPositionId')
    },
    // 周转容器类型
    {
      prop: 'containerTypeId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerType'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.containerTypeList, row[column])
      }
    },
    // 周转容器编码
    {
      prop: 'containerArchivesId',
      isShow: !that.executeMode,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      formatter(row, column) {
        return getFormatter(options.containerArchivesAllList, row[column])
      }
    },
    // 周转容器编码
    {
      prop: 'externalTurnoverContainerCode',
      isShow: that.executeMode === 1,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerId')
    },
    // 周转容器数量
    {
      prop: 'containerNumber',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerSum')
    },
    // 物料一级分类
    {
      prop: 'materialsTypeLevelName',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialFirstType')
    },
    // 物料二级分类
    {
      prop: 'materialsTypeLevelSecName',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialSecondType')
    },
    // 物料编码
    {
      prop: 'materialsId',
      isShow: !that.executeMode,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialId'),
      formatter(row, column) {
        return getFormatter(options.materialCodeList, row[column])
      }
    },
    // 物料编码
    {
      prop: 'externalMaterialsCode',
      isShow: that.executeMode === 1,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialId')
    },
    // 物料名称
    {
      prop: 'materialsName',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialName'),
      formatter(row, column) {
        return getFormatter(options.materialNameList, row[column])
      }
    },
    // 物料数量
    {
      prop: 'materialsNumber',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialSum')
    },
    // 基本单位
    {
      prop: 'materialsUnit',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.common.basicUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 计量数量
    {
      prop: 'measureNumber',
      isShow: true,
      label: that.$t('lang.gles.stock.measuredNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 计量单位
    {
      prop: 'measureUnit',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.common.measureUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 物料朝向
    {
      prop: 'materialsDirection',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialToward')
    },
    // 宽
    {
      prop: 'materialSpec',
      isShow: true,
      label: `${that.$t('lang.gles.base.width')}(mm)`
    },
    // // 批次
    // {
    //   prop: 'measureNumber',
    //   isShow: true,
    //   label: that.$t('lang.gles.orderManage.batchNo')
    // },
    // 等级状态
    {
      prop: 'degreeStatus',
      isShow: true,
      label: that.$t('lang.gles.orderManage.levelStatus'),
      formatter(row, column) {
        return getFormatter(options.gradeStatusList, row[column])
      }
    },
    {
      prop: 'checkMaterialStatus',
      isShow: true,
      label: that.$t('lang.gles.orderManage.checkStatus'),
      formatter(row, column) {
        return getFormatter(options.checkStatusList, row[column])
      }
    },
    // 批属性
    {
      prop: 'batchProperties',
      isShow: true,
      label: that.$t('lang.gles.orderManage.batchProperties'),
      slotName: 'clickSlot'
    }
  ]
  return searchTableItem.filter(t => t.isShow)
}

// 容器/物料详情列表(无容器)
export const getDetailNoContainerTableItems = (that, options) => {
  const searchTableItem = [
    // 周转容器类型
    {
      prop: 'containerTypeId',
      isShow: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerType'),
      width: 110,
      showOverflowTooltip: true,
      formatter(row, column) {
        return getFormatter(options.containerTypeList, row[column])
      }
    },
    // 周转容器编码
    {
      prop: 'containerArchivesId',
      isShow: !that.executeMode,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      formatter(row, column) {
        return getFormatter(options.containerArchivesAllList, row[column])
      }
    },
    // 周转容器编码
    {
      prop: 'externalTurnoverContainerCode',
      isShow: that.executeMode === 1,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerId')
    },
    // 周转容器数量
    {
      prop: 'containerNumber',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.turnoverContainerSum'),
      width: 110
    },
    // 物料一级分类
    {
      prop: 'materialsTypeLevelName',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialFirstType'),
      width: 110
    },
    // 物料二级分类
    {
      prop: 'materialsTypeLevelSecName',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialSecondType')
    },
    // 物料编码
    {
      prop: 'materialsId',
      isShow: !that.executeMode,
      width: 100,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialId'),
      formatter(row, column) {
        return getFormatter(options.materialCodeList, row[column])
      }
    },
    // 物料编码
    {
      prop: 'externalMaterialsCode',
      isShow: that.executeMode === 1,
      width: 100,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialId')
    },
    // 物料名称
    {
      prop: 'materialsName',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialName'),
      formatter(row, column) {
        return getFormatter(options.materialNameList, row[column])
      }
    },
    // 物料数量
    {
      prop: 'materialsNumber',
      isShow: true,
      label: that.$t('lang.gles.orderManage.materialSum')
    },
    // 基本单位
    {
      prop: 'materialsUnit',
      isShow: true,
      label: that.$t('lang.gles.common.basicUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 计量数量
    {
      prop: 'measureNumber',
      isShow: true,
      label: that.$t('lang.gles.stock.measuredNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 计量单位
    {
      prop: 'measureUnit',
      isShow: true,
      label: that.$t('lang.gles.common.measureUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnitList, row[column])
      }
    },
    // 物料朝向
    {
      prop: 'materialDirectionName',
      isShow: true,
      showOverflowTooltip: true,
      label: that.$t('lang.gles.orderManage.materialToward')
    },
    // 宽
    {
      prop: 'materialSpec',
      isShow: true,
      label: `${that.$t('lang.gles.base.width')}(mm)`
    },
    // // 批次
    // {
    //   prop: 'measureNumber',
    //   isShow: true,
    //   label: that.$t('lang.gles.orderManage.batchNo'),
    //   width: 100
    // },
    // 等级状态
    {
      prop: 'degreeStatus',
      isShow: true,
      label: that.$t('lang.gles.orderManage.levelStatus'),
      formatter(row, column) {
        return getFormatter(options.gradeStatusList, row[column])
      }
    },
    {
      prop: 'checkMaterialStatus',
      isShow: true,
      label: that.$t('lang.gles.orderManage.checkStatus'),
      formatter(row, column) {
        return getFormatter(options.checkStatusList, row[column])
      }
    },
    // 批属性
    {
      prop: 'batchProperties',
      isShow: true,
      label: that.$t('lang.gles.orderManage.batchProperties'),
      slotName: 'clickSlot'
    },
    // 指定入/出库库区
    {
      prop: 'warehouseAreaId',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label:
        that.materialsType === '2'
          ? that.$t('lang.gles.orderManage.assignInWarehouseAreaId')
          : that.$t('lang.gles.orderManage.assignOutWarehouseAreaId'),
      formatter(row, column) {
        return getFormatter(options.warehouseAreaList, row[column])
      }
    },
    // 指定入/出库库位
    {
      prop: 'factoryPositionId',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label:
        that.materialsType === '2'
          ? that.$t('lang.gles.orderManage.assignInWarehousePositionId')
          : that.$t('lang.gles.orderManage.assignOutWarehousePositionId'),
      formatter(row, column) {
        return options.factoryPositionList.find(
          (item) => item.value === row[column]
        )?.label
      }
    },
    // 指定入/出库货位
    {
      prop: 'goodsPositionId',
      isShow: true,
      width: 110,
      showOverflowTooltip: true,
      label:
        that.materialsType === '2'
          ? that.$t('lang.gles.orderManage.assignInGoodsPositionId')
          : that.$t('lang.gles.orderManage.assignOutGoodsPositionId'),
      formatter(row, column) {
        return options.goodsPositionList.find(
          (item) => item.value === row[column]
        )?.label
      }
    }
  ]
  return searchTableItem.filter(t => t.isShow)
}

// 获取任务详情table
export const getTaskTableItems = (that, options) => [
  // 任务ID
  {
    prop: 'taskCode',
    isShow: true,
    label: `${that.$t('lang.gles.orderManage.task')}ID`,
    width: 100,
    showOverflowTooltip: true
  },
  // 作业类型
  {
    prop: 'taskType',
    isShow: true,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.operatingType'),
    formatter(row, column) {
      return getFormatter(options.subTaskTypeList, row[column])
    }
  },
  // 目标库位/货位
  {
    prop: 'targetPositionCode',
    isShow: true,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.targetPosition'),
    width: 115
  },
  // 作业方式
  {
    prop: 'workMode',
    isShow: true,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.operatingMode'),
    formatter(row, column) {
      return getFormatter(options.operatingModeList, row[column])
    }
  },
  // 机器人任务ID
  {
    prop: 'robotTaskId',
    isShow: true,
    width: 110,
    showOverflowTooltip: true,
    label: `${that.$t('lang.gles.orderManage.robotTaskId')}ID`
  },
  // 机器人编号
  {
    prop: 'robotCode',
    isShow: true,
    width: 110,
    label: that.$t('lang.gles.orderManage.robotCode')
  },
  // 任务状态
  {
    prop: 'taskStatus',
    isShow: true,
    width: 100,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.taskStatus'),
    formatter(row, column) {
      return getFormatter(options.taskStatusList, row[column])
    }
  },
  // 容器编码
  {
    prop: 'containerArchivesId',
    isShow: true,
    width: 100,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.containerId'),
    formatter(row, column) {
      return getFormatter(options.containerArchivesAllList, row[column])
    }
  },
  // 货位编码
  {
    prop: 'goodsPositionId',
    isShow: true,
    width: 100,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.base.goodsPositionCode'),
    formatter(row, column) {
      return options.goodsPositionList.find(
        (item) => item.value === row[column]
      )?.label
    }
  },
  // 周转容器编码
  {
    prop: 'turnoverContainerArchivesId',
    isShow: true,
    width: 110,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.turnoverContainerId'),
    formatter(row, column) {
      return getFormatter(options.containerArchivesAllList, row[column])
    }
  },
  // 周转容器数量
  {
    prop: 'turnoverContainerSum',
    isShow: true,
    width: 110,
    label: that.$t('lang.gles.orderManage.turnoverContainerSum')
  },
  // 物料名称
  {
    prop: 'materialName',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialName'),
    width: 100,
    showOverflowTooltip: true,
    formatter(row, column) {
      return getFormatter(options.materialNameList, row[column])
    }
  },
  // 物料编码
  {
    prop: 'materialsId',
    isShow: true,
    width: 100,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.materialId'),
    formatter(row, column) {
      return getFormatter(options.materialCodeList, row[column])
    }
  },
  // 物料数量
  {
    prop: 'materialSum',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialSum')
  },
  // 物料朝向
  {
    prop: 'materialDirectionName',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialToward')
  },
  // 批次
  {
    prop: 'materialBatchNo',
    isShow: true,
    label: that.$t('lang.gles.orderManage.batchNo')
  },
  // 等级状态
  {
    prop: 'materialGrade',
    isShow: true,
    label: that.$t('lang.gles.orderManage.levelStatus'),
    formatter(row, column) {
      return getFormatter(options.gradeStatusList, row[column])
    }
  },
  // 创建时间
  {
    prop: 'createTime',
    isShow: true,
    width: 110,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.common.createTime')
  },
  // 完成时间
  {
    prop: 'finishTime',
    isShow: true,
    width: 110,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.common.completedTime')
  }
]

// 获取任务详情子table
export const getTaskChildTableItems = (that, options) => [
  // 容器编码
  {
    prop: 'containerId',
    isShow: true,
    label: that.$t('lang.gles.orderManage.containerId'),
    formatter(row, column) {
      return getFormatter(options.containerArchivesAllList, row[column])
    }
  },
  // 货位编码
  {
    prop: 'goodsPositionId',
    isShow: true,
    label: that.$t('lang.gles.orderManage.deviceGoodsPositionId'),
    formatter(row, column) {
      return options.goodsPositionList.find(
        (item) => item.value === row[column]
      )?.label
    }
  },
  // 周转容器编码
  {
    prop: 'turnoverContainerId',
    isShow: true,
    label: that.$t('lang.gles.orderManage.turnoverContainerId'),
    formatter(row, column) {
      return getFormatter(options.containerArchivesAllList, row[column])
    }
  },
  // 周转容器数量
  {
    prop: 'turnoverContainerSum',
    isShow: true,
    label: that.$t('lang.gles.orderManage.turnoverContainerSum')
  },
  // 物料名称
  {
    prop: 'materialCode',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialName'),
    formatter(row, column) {
      return getFormatter(options.materialNameList, row[column])
    }
  },
  // 物料编码
  {
    prop: 'materialId',
    isShow: true,
    showOverflowTooltip: true,
    label: that.$t('lang.gles.orderManage.materialId'),
    formatter(row, column) {
      return getFormatter(options.materialCodeList, row[column])
    }
  },
  // 物料数量
  {
    prop: 'materialNum',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialSum')
  },
  // 物料朝向
  {
    prop: 'materialToward',
    isShow: true,
    label: that.$t('lang.gles.orderManage.materialToward')
  },
  // 批次
  {
    prop: 'batchNo',
    isShow: true,
    label: that.$t('lang.gles.orderManage.batchNo')
  },
  // 等级状态
  {
    prop: 'levelStatus',
    isShow: true,
    label: that.$t('lang.gles.orderManage.levelStatus'),
    formatter(row, column) {
      return getFormatter(options.gradeStatusList, row[column])
    }
  }
]

// 获取日志table
export const getLogTableItems = (that, options) => [
  // 状态
  {
    prop: 'taskStatus',
    label: that.$t('lang.gles.base.status'),
    formatter(row, column) {
      return getFormatter(options.materialsBillStatusList, row[column])
    }
  },
  // 时间
  {
    prop: 'createTime',
    label: that.$t('lang.gles.common.time'),
    formatter: { type: 'time' }
  },
  // 操作人
  {
    prop: 'createUser',
    label: that.$t('lang.gles.common.handleName')
  }
]
